<template>
  <b-list-group-item class="d-flex justify-content-between">
    <div class="font-weight-bold text-primary">
      {{ itemLabel }}
    </div>
    <div>{{ itemValue }}</div>
  </b-list-group-item>
</template>

<script>
import { BListGroupItem } from 'bootstrap-vue'

export default {
  name: 'CompanyListItem',
  components: { BListGroupItem },
  props: {
    itemLabel: {
      type: String,
      required: true,
    },
    itemValue: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <b-list-group>
    <company-list-item
      v-if="dataItem.customer_company"
      :item-label="$t('Firma Adı')"
      :item-value="dataItem.customer_company"
    />
    <company-list-item
      v-if="dataItem.customer_phone"
      :item-label="$t('Telefon')"
      :item-value="dataItem.customer_phone"
    />
    <company-list-item
      v-if="dataItem.customer_email"
      :item-label="$t('E-Posta')"
      :item-value="dataItem.customer_email"
    />
    <company-list-item
      v-if="dataItem.customer_address"
      :item-label="$t('Adres')"
      :item-value="dataItem.customer_address"
    />
    <company-list-item
      v-if="dataItem.customer_tax_office"
      :item-label="$t('Vergi Dairesi')"
      :item-value="dataItem.customer_tax_office"
    />
    <company-list-item
      v-if="dataItem.customer_tax_number"
      :item-label="$t('Vergi No')"
      :item-value="dataItem.customer_tax_number"
    />
  </b-list-group>
</template>

<script>
import { BListGroup } from 'bootstrap-vue'
import CompanyListItem from '@/views/Client/Offers/components/CompanyListItem.vue'

export default {
  name: 'CustomerCard',
  components: {
    CompanyListItem,
    BListGroup,
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/dataItem']
    },
  },
}
</script>

<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>
        {{ dataItem.title }}
      </b-card-title>
      <b-card-title class="text-primary">
        {{ $t('Teklif No') }}: {{ dataItem.onumber }}
      </b-card-title>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col>
          <div class="border p-2 rounded text-center">
            <div class="font-weight-bold text-primary">
              {{ $t('Teklif Tarihi') }}
            </div>
            <div>{{ moment(dataItem.odate).format('DD.MM.YYYY') }}</div>
          </div>
        </b-col>
        <b-col>
          <div class="border p-2 rounded text-center">
            <div class="font-weight-bold text-primary">
              {{ $t('Teklif Geçerlilik Tarihi') }}
            </div>
            <div>{{ moment(dataItem.vdate).format('DD.MM.YYYY') }}</div>
          </div>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <customer-card />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <company-card />
        </b-col>
      </b-row>
      <hr>
      <div v-if="dataItem.content">
        <div class="font-weight-bold text-primary">
          {{ $t('Teklif Mesajı') }}
        </div>
        <div v-html="dataItem.content" />
      </div>
    </b-card-body>
    <offer-lines v-if="dataItem.lines.length" />
    <b-card-footer class="d-flex justify-content-end">
      <b-button
        v-if="dataItem.id_offer_statuses === '2'"
        variant="success"
        @click="confirm"
      >
        <FeatherIcon icon="CheckCircleIcon" />
        {{ $t('Teklifi Onayla') }}
      </b-button>
    </b-card-footer>
  </b-card>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BRow, BCol, BCardFooter, BButton,
} from 'bootstrap-vue'
import CustomerCard from '@/views/Client/Offers/components/CustomerCard.vue'
import CompanyCard from '@/views/Client/Offers/components/CompanyCard.vue'
import OfferLines from '@/views/Client/Offers/components/OfferLines.vue'
import moment from 'moment'

export default {
  name: 'OfferView',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BCardFooter,
    BButton,
    CustomerCard,
    CompanyCard,
    OfferLines,
  },
  computed: {
    moment() {
      return moment
    },
    dataItem() {
      return this.$store.getters['offers/dataItem']
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('offers/getData', {
        id: this.$route.params.id,
      })
    },
    confirm() {
      this.$swal({
        title: this.$i18n.t('Uyarı!'),
        text: this.$i18n.t('Teklifi onaylıyor musunuz?'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Devam Et'),
        cancelButtonText: this.$i18n.t('Vazgeç'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('offers/confirm', {
            id: this.dataItem.id,
          })
            .then(response => {
              if (response.status) {
                this.getData()
                this.$swal({
                  icon: 'success',
                  title: this.$i18n.t('İşlem Başarılı!'),
                  text: response.message,
                  confirmButtonText: this.$i18n.t('Kapat'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<template>
  <b-list-group>
    <company-list-item
      v-if="dataItem.title"
      :item-label="$t('Firma Adı')"
      :item-value="dataItem.title"
    />
    <company-list-item
      v-if="dataItem.phone"
      :item-label="$t('Telefon')"
      :item-value="dataItem.phone"
    />
    <company-list-item
      v-if="dataItem.email"
      :item-label="$t('E-Posta')"
      :item-value="dataItem.email"
    />
    <company-list-item
      v-if="dataItem.address"
      :item-label="$t('Adres')"
      :item-value="dataItem.address"
    />
    <company-list-item
      v-if="dataItem.tax_office"
      :item-label="$t('Vergi Dairesi')"
      :item-value="dataItem.tax_office"
    />
    <company-list-item
      v-if="dataItem.tax_no"
      :item-label="$t('Vergi No')"
      :item-value="dataItem.tax_no"
    />
  </b-list-group>
</template>

<script>
import { BListGroup } from 'bootstrap-vue'
import CompanyListItem from '@/views/Client/Offers/components/CompanyListItem.vue'

export default {
  name: 'CompanyCard',
  components: {
    CompanyListItem,
    BListGroup,
  },
  computed: {
    dataItem() {
      return this.$store.getters['company/dataItem']
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('company/getData')
    },
  },
}
</script>

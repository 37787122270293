<template>
  <b-table-simple>
    <b-thead>
      <b-tr>
        <b-th>{{ $t('Ürün Kodu') }}</b-th>
        <b-th>{{ $t('Miktar') }}</b-th>
        <b-th>{{ $t('Birim Fiyatı') }}</b-th>
        <b-th class="text-right width-50 text-nowrap">
          {{ $t('Toplam Tutar') }}
        </b-th>
      </b-tr>
    </b-thead>
    <b-tbody>
      <b-tr
        v-for="item in dataItem.lines"
        :key="item.id"
      >
        <b-td>
          {{ item.title }}
          <div class="font-small-2 text-warning">
            {{ item.content }}
          </div>
        </b-td>
        <b-td>
          {{ item.quantity | toNumber }} {{ item.unit }}
        </b-td>
        <b-td>
          {{ item.listed_price | toCurrency }} {{ item.currency }}
        </b-td>
        <b-td class="text-right text-nowrap">
          {{ item.total_price | toCurrency }} {{ item.currency }}
        </b-td>
      </b-tr>
    </b-tbody>
    <b-tfoot>
      <b-tr>
        <b-td
          colspan="3"
          class="text-right font-weight-bold"
        >
          {{ $t('Ara Toplam') }}
        </b-td>
        <b-td class="text-right text-nowrap">
          {{ dataItem.listed_total | toCurrency }} {{ dataItem.lines[0].currency }}
        </b-td>
      </b-tr>
      <b-tr>
        <b-td
          colspan="3"
          class="text-right font-weight-bold"
        >
          {{ $t('İndirimli Tutar') }}
        </b-td>
        <b-td class="text-right text-nowrap">
          {{ dataItem.discounted_total | toCurrency }} {{ dataItem.lines[0].currency }}
        </b-td>
      </b-tr>
      <b-tr>
        <b-td
          colspan="3"
          class="text-right font-weight-bold"
        >
          {{ $t('Vergiler Toplamı') }}
          <div class="text-muted">
            (<span
              v-for="(tax,index) in dataItem.tax_rates"
              :key="index"
            >
              %{{ tax }}
            </span>)
          </div>
        </b-td>
        <b-td class="text-right text-nowrap">
          {{ dataItem.taxes_total | toCurrency }} {{ dataItem.lines[0].currency }}
        </b-td>
      </b-tr>
      <b-tr>
        <b-td
          colspan="3"
          class="text-right font-weight-bold"
        >
          {{ $t('Genel Toplam') }}
        </b-td>
        <b-td class="text-right text-nowrap">
          {{ dataItem.grand_total | toCurrency }} {{ dataItem.lines[0].currency }}
        </b-td>
      </b-tr>
    </b-tfoot>
  </b-table-simple>
</template>

<script>
import {
  BTableSimple, BThead, BTr, BTh, BTd, BTbody, BTfoot,
} from 'bootstrap-vue'

export default {
  name: 'OfferLines',
  components: {
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/dataItem']
    },
  },
}
</script>
